


































































































































import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { MAX_ITEMS_PER_PAGE, RunQueryOptions } from '@/module/api/common';
import { useDomainApi } from '@/module/api/domain';
import { DomoModel, useDomoApi } from '@/module/api/domo';
import { getFriendlyLoadJobTypeName, useLoadJobApi } from '@/module/api/load-job';
import { canSeeEntireOperation, useAuth } from '@/module/auth';
import { DateTimeMethod, formatDbDate, saveTextToClipboard, usePagination } from '@/utils';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import * as qs from 'qs';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const { assertionContext } = useAuth();

const {
  areThereMorePages,
  getNextPageQueryParams,
  emptyPageableTable,
  resetPageableTable,
  cachePage,
  applyPageChange,
} = usePagination(MAX_ITEMS_PER_PAGE);

const toast = createToastInterface({ maxToasts: 3 });

const { selectedItem: selectedDomain, isLoading: isDomainLoading, refreshItem: refreshDomain } = useDomainApi();

const { isLoading: isLoadJobLoading, selectedItem: selectedLoadJob, refreshItem: refreshLoadJob } = useLoadJobApi();

const { getItems: getDomos, items: domos, isLoading: isDomoLoading } = useDomoApi();

const domainDialog = ref<any>();

const emptyState = () => {
  return {
    dialog: false,
    loadJobUuid: '',
    domainUuid: '',
    domoSetUuid: '',
    filter: [] as Partial<Record<string, any>>[],
    systemFilters: {
      'filter[domoSetUuid]': '',
      'sort[operationType]': '1',
      'sort[entityType]': '1',
    } as Record<string, string>,
    domoDetailsDialog: false,
    data: [] as Partial<DomoModel[]>,
    currentDomo: {} as Partial<DomoModel>,
  };
};

const state = reactive(emptyState());

const domoTable = reactive({
  ...emptyPageableTable(),
  headers: [
    { text: '', value: 'uuid', width: '32px', sortable: false },
    { text: 'State', value: 'state', sortable: false },
    { text: 'Op', value: 'operationType', sortable: false },
    { text: 'Type', value: 'entityType', sortable: false },
    { text: 'Name', value: 'dombName', sortable: false },
    { text: 'ID', value: 'entityId', sortable: false },
    { text: 'Parent ID', value: 'parentEntityId', sortable: false },
    { text: 'Reason', value: 'reason', sortable: false },
  ],
  itemsPerPage: MAX_ITEMS_PER_PAGE,
});

const viewDomain = () => {
  Vue.$log.debug('Clicked view dialog');
  domainDialog.value.view(selectedDomain);
};

const addFilter = (filter: any[]) => {
  filter.push({ key: '', value: '' });
};

const filterAsQueryParams = computed((): string[][] => {
  try {
    const urlSearchParamsQuery = state.filter.reduce((query: any[], rec: any) => {
      if (rec.key && rec.key !== '' && rec.value) {
        query.push([rec.key, rec.value]);
      }
      return query;
    }, []);
    return urlSearchParamsQuery;
  } catch (e) {
    return [];
  }
});

const queryParamFormattedFilter = computed(() => {
  return state.filter.map(rec => {
    return { [rec.key]: rec.value };
  });
});

const getDomoSearchResults = async () => {
  for (const filter in state.systemFilters) {
    filterAsQueryParams.value.push([filter, state.systemFilters[filter]]);
  }
  const getDomosRes = await getDomos({
    queryAsSearchParams: filterAsQueryParams.value,
    raw: true,
  });
  resetPageableTable(domoTable);
  const areMorePages = areThereMorePages(getDomosRes);
  if (areMorePages) {
    domoTable.pageCount = 2;
  }
  domoTable.data = getDomosRes._embedded;
  cachePage(domoTable, domoTable.data);
};

const addDefaultFilters = () => {
  Vue.$log.debug('When adding default filters, this is our domain and state', state, selectedDomain);
  if (state.domoSetUuid !== '' && state.systemFilters['filter[domoSetUuid]'] !== state.domoSetUuid) {
    state.systemFilters['filter[domoSetUuid]'] = state.domoSetUuid;
  }
};

const refreshItems = async (forceUpdate = false): Promise<void> => {
  await refreshDomain(state.domainUuid, forceUpdate);
  await refreshLoadJob(state.loadJobUuid, forceUpdate);

  if (forceUpdate || state.domoSetUuid !== selectedLoadJob.value?.domoSetUuid) {
    state.domoSetUuid = selectedLoadJob.value.domoSetUuid;
  }

  if (!state.domoSetUuid) {
    Vue.$log.error(
      'Could not refresh all items. Unable to derive domoSetUuid from loadJob. Returning without refreshing domos',
    );
    return;
  }
  addDefaultFilters();
  await getDomoSearchResults();
};

const queryDomos = async (runQueryOptions: RunQueryOptions) => {
  const pageQuery = runQueryOptions.nextPage
    ? getNextPageQueryParams({
        page: domoTable.page,
      })
    : { page: domoTable.page };
  pageQuery.limit = 50;
  if (pageQuery.page !== undefined) {
    filterAsQueryParams.value.push(['page', String(pageQuery.page)]);
  }
  if (pageQuery.limit !== undefined) {
    filterAsQueryParams.value.push(['limit', String(pageQuery.limit)]);
  }
  for (const filter in state.systemFilters) {
    filterAsQueryParams.value.push([filter, state.systemFilters[filter]]);
  }

  const getParams = {
    queryAsSearchParams: filterAsQueryParams.value,
    // query: {
    //   ...pageQuery,
    //   ...filterAsQueryParams.value,
    //   ...state.systemFilters,
    // },
    raw: true,
  };
  const response = await getDomos(getParams);
  domoTable.data = response?._embedded;
  cachePage(domoTable, domoTable.data);
  const areMorePages = areThereMorePages(response);
  if (areMorePages) {
    domoTable.pageCount = domoTable.pageCount + 1;
  }
  return domoTable.data;
};

const getNextPage = async () => {
  applyPageChange(domoTable, async () => await queryDomos({ nextPage: true }));
};

const showDomoDetailDialog = (item: DomoModel) => {
  state.currentDomo = item;
  state.domoDetailsDialog = true;
};

const reset = async () => {
  Object.assign(state, emptyState());
};

const clipyboardCopyWithNotification = (textToCopy: string) => {
  saveTextToClipboard(textToCopy);
  toast.success('Copied text to clipboard.');
};

const jsonPretty = (obj: any) => {
  if (!assertionContext.value?.roles || !canSeeEntireOperation(assertionContext.value.roles)) {
    return JSON.stringify(
      {
        uuid: obj.uuid,
        name: obj.dombName,
        id: obj.entityId,
        parentId: obj.parentEntityId,
        type: obj.entityType,
        operation: obj.operationType,
      },
      null,
      2,
    );
  }
  try {
    obj.operationResult.request.headers.Authorization = '[REDACTED]';
  } catch (e) {
    // its fine.
  }
  return JSON.stringify(obj, null, 2);
};

// We have to take the filter, map it to a key-value, then map it to a deep object, grab the filter, and map it back to a key-value.
const copyFilterToClipboard = () => {
  let text = '';
  try {
    const urlSearchParamsQuery = state.filter.reduce((query: any[], rec: any) => {
      if (rec.key && rec.key !== '' && rec.value) {
        query.push([rec.key, rec.value]);
      }
      return query;
    }, []);
    const urlSearchParams = new URLSearchParams(urlSearchParamsQuery);
    const deepObjectQuery = qs.parse(urlSearchParams.toString());
    if (!deepObjectQuery || typeof deepObjectQuery !== 'object' || Object.keys(deepObjectQuery).length < 1) {
      throw new Error('Could not obtain deep query');
    }
    text = JSON.stringify(deepObjectQuery.filter);
    if (!text || text === 'undefined') {
      throw new Error('Could not stringify');
    }
  } catch (e) {
    toast.error('Could not build a filter query. Try changing the filter.');
    return;
  }

  clipyboardCopyWithNotification(text);
};

export default defineComponent({
  name: 'LoadJobOperationBrowser',
  components: { RefreshButton, DomainDialog, DomainWorkspaceToolbar },
  props: {
    uuid: {
      type: String,
    },
    jobUuid: {
      type: String,
    },
    backLinkOverride: {
      type: String,
      required: false
    }
  },
  setup(props) {
    reset();
    state.loadJobUuid = props.jobUuid || '';
    state.domainUuid = props.uuid || '';
    refreshItems();
    return {
      ...toRefs(state),
      reset,
      formatDbDate,
      refreshItems,
      isUserAdmin: true,
      domos,
      domainDialog,
      viewDomain,
      isDomoLoading,
      isDomainLoading,
      isLoadJobLoading,
      getFriendlyLoadJobTypeName,
      selectedLoadJob,
      applyPageChange,
      domoTable,
      addFilter,
      saveTextToClipboard,
      queryParamFormattedFilter,
      DateTimeMethod,
      queryDomos,
      jsonPretty,
      clipyboardCopyWithNotification,
      copyFilterToClipboard,
      jobType: 'Import',
      backLink: props.backLinkOverride || `/domain/${props.uuid}/job/import/${props.jobUuid}`,
      showDomoDetailDialog,
      getDomoSearchResults,
      selectedDomain,
      commonFilters: [
        'filter[entityType]',
        'filter[entityId]',
        'filter[entityId][$in]',
        'filter[parentEntityId]',
        'filter[dombName]',
        'filter[state]',
        'filter[uuid]',
      ],
      getNextPage,
    };
  },
});
